export let colorStandardBlack = '#000000';
export const colorTurquoiseGreen = '#9AD1BF';
export const colorTurquoiseGreenDark = '#66C2A4';
export const colorTurquoiseGreenExtraDark = '#3b796a';
export const colorSonicSilver = '#7A7A7A';
export const colorDarkPuce = '#483B3D';
export const colorAntiFlashWhite = '#F2F2F2';
export const colorAlmond = '#F0DECE';
export const colorMauvelous = '#F39DAD';
export const colorStandardWhite = '#FFFFFF';
export const colorFormGrey = '#545454';
