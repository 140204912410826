export const MODAL_CONTENT_TYPES = {
  COUPON: 'coupon',
  PARTNERSHIPS: 'partnerships',
  RESALES: 'resales',
  EXPORTS: 'exports',
  PRODUCT_COMPOSITION: 'productComposition',
  TIP_OVERFLOW: 'tipOverflow',
  TIP_UNDERFLOW: 'tipUnderflow',
  CANCELLED_ORDER: 'cancelledOrder',
  PRODUCT_DOUBT: 'productDoubt',
  NON_CUSTOMER_PRODUCT_DOUBT: 'nonCustomerProductDoubt',
  ORDER_DOUBT: 'orderDoubt',
  PRODUCT_RETURN: 'productReturn',
  DELIVERY_DEADLINES: 'deliveryDeadlines',
  ORDER_TRACKING: 'orderTracking',
  ORDER_STATUS: 'orderStatus',
  LATE_ORDER: 'lateOrder',
  CHANGE_SHIPPING_ADDRESS: 'changeShippingAddress',
  CHANGE_ORDER: 'changeOrder',
  PAYMENT_METHODS: 'paymentMethods',
  CANCELLED_TRANSACTION: 'cancelledTransaction',
  LOOSE_TIP: 'looseTip',
};
