import { ADDRESS_FORM_FIELDS } from 'modules/Forms/constants/AddressForm.constants';
import { EXISTING_CUSTOMER_ID_FORM_FIELDS } from 'modules/Forms/constants/ExistingCustomerIdentificationForm.constants';
import { NON_CUSTOMER_FORM_FIELDS } from 'modules/Forms/constants/NonCustomerIdentificationForm.constants';
import { MESSAGE_ENTRY_FORM_FIELDS } from 'modules/Forms/constants/MessageEntryForm.constants';

export const HIGH_LEVEL_CATEGORIES = {
  ADDRESS: 'address',
  IDENTIFICATION: 'identification',
  MESSAGE: 'message',
};

export const FORM_FIELD_CATEGORIES = {
  [ADDRESS_FORM_FIELDS.ZIP]: HIGH_LEVEL_CATEGORIES.ADDRESS,
  [ADDRESS_FORM_FIELDS.STREET]: HIGH_LEVEL_CATEGORIES.ADDRESS,
  [ADDRESS_FORM_FIELDS.NUMBER]: HIGH_LEVEL_CATEGORIES.ADDRESS,
  [ADDRESS_FORM_FIELDS.COMPLEMENT]: HIGH_LEVEL_CATEGORIES.ADDRESS,
  [ADDRESS_FORM_FIELDS.DISTRICT]: HIGH_LEVEL_CATEGORIES.ADDRESS,
  [ADDRESS_FORM_FIELDS.CITY]: HIGH_LEVEL_CATEGORIES.ADDRESS,
  [ADDRESS_FORM_FIELDS.STATE]: HIGH_LEVEL_CATEGORIES.ADDRESS,
  [EXISTING_CUSTOMER_ID_FORM_FIELDS.ID]: HIGH_LEVEL_CATEGORIES.IDENTIFICATION,
  [EXISTING_CUSTOMER_ID_FORM_FIELDS.NAME]: HIGH_LEVEL_CATEGORIES.IDENTIFICATION,
  [EXISTING_CUSTOMER_ID_FORM_FIELDS.ORDER_NUMBER]: HIGH_LEVEL_CATEGORIES.IDENTIFICATION,
  [EXISTING_CUSTOMER_ID_FORM_FIELDS.EMAIL]: HIGH_LEVEL_CATEGORIES.IDENTIFICATION,
  [NON_CUSTOMER_FORM_FIELDS.EMAIL]: HIGH_LEVEL_CATEGORIES.IDENTIFICATION,
  [NON_CUSTOMER_FORM_FIELDS.NAME]: HIGH_LEVEL_CATEGORIES.IDENTIFICATION,
  [MESSAGE_ENTRY_FORM_FIELDS.SUBJECT]: HIGH_LEVEL_CATEGORIES.MESSAGE,
  [MESSAGE_ENTRY_FORM_FIELDS.MESSAGE]: HIGH_LEVEL_CATEGORIES.MESSAGE,
};
